import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import {OAuthService} from "angular-oauth2-oidc";
import {Observable} from "rxjs";
import {Storage} from "@ionic/storage";
import {ApiService} from '../../services/api.service';

@Injectable({
    providedIn: "root"
})
export class WizardGuardService implements CanActivate {
    constructor(private router: Router, private oauthService: OAuthService, private apiService: ApiService, private storage: Storage) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.oauthService.getRefreshToken() != null) {
            return new Promise((resolve, reject) => {
                this.oauthService.refreshToken().then(() => {
                    this.apiService.profilApi.getMe().then((u : any) => {
                        if(u.is_admin === true || u.show_wizard == true){
                            this.router.navigateByUrl('/');
                            resolve(false);
                        }
                        else{
                            resolve(true);
                        }
                    }).catch(() => {
                        this.storage.remove('user');
                        this.storage.remove('projects');
                        this.oauthService.logOut(false);
                        window.location.href = "/auth/login";
                        resolve(false);
                    });
                }).catch(() => {
                    this.router.navigateByUrl('/auth/login');
                    resolve(false);
                });
            });
        } else {
            this.router.navigateByUrl('/auth/login');
            return false;
        }

    }
}
