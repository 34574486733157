import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from "@ionic/storage";

export class AdminWidgetApi {
  constructor(private storage: Storage, private http: HttpClient) {

  }



  getUserData() {
        return this.http
            .get(`${environment.apiUrl}/api/admin/widget/users`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
        }});
  }


  getExerciceStatData(startDate:any,endDate:any, type:any, filter : any) {
        let params = "?";

      if(startDate != null){ params = params + "&start_date="+startDate; }
      if(endDate != null){ params = params + "&end_date="+endDate; }
        if(type != null){ params = params + "&type="+type; }
        if(filter != null){ params = params + "&filter="+filter; }

        return this.http
            .get(`${environment.apiUrl}/api/admin/widget/exercicesstats${params}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
  }



    getUserStatData(startDate:any,endDate:any) {
        let params = "?";

        if(startDate != null){ params = params + "&start_date="+startDate; }
        if(endDate != null){ params = params + "&end_date="+endDate; }

        return this.http
            .get(`${environment.apiUrl}/api/admin/widget/usersstats${params}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }





    getPatientStatData(startDate:any,endDate:any) {
        let params = "?length="+length;

        if(startDate != null){ params = params + "&start_date="+startDate; }
        if(endDate != null){ params = params + "&end_date="+endDate; }

        return this.http
            .get(`${environment.apiUrl}/api/admin/widget/patientsstats${params}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }


    getCaStatData(startDate:any,endDate:any) {
        let params = "?length="+length;

        if(startDate != null){ params = params + "&start_date="+startDate; }
        if(endDate != null){ params = params + "&end_date="+endDate; }

        return this.http
            .get(`${environment.apiUrl}/api/admin/widget/castats${params}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }


    getAppareilStatData(startDate:any,endDate:any) {
        let params = "?length="+length;

        if(startDate != null){ params = params + "&start_date="+startDate; }
        if(endDate != null){ params = params + "&end_date="+endDate; }

        return this.http
            .get(`${environment.apiUrl}/api/admin/widget/devicesstats${params}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }


    getAbonnementStatData(startDate:any,endDate:any) {
        let params = "?length="+length;

        if(startDate != null){ params = params + "&start_date="+startDate; }
        if(endDate != null){ params = params + "&end_date="+endDate; }

        return this.http
            .get(`${environment.apiUrl}/api/admin/widget/abonnementstats${params}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }




    getAppareilData() {
        return this.http
            .get(`${environment.apiUrl}/api/admin/widget/devices`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
  }


  getPatientData() {
        return this.http
            .get(`${environment.apiUrl}/api/admin/widget/patients`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
  }


    getAbonnementData() {
        return this.http
            .get(`${environment.apiUrl}/api/admin/widget/abonnement`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }

    getAbonnementStateData() {
        return this.http
            .get(`${environment.apiUrl}/api/admin/widget/abonnementstate`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }


    getUseData() {
        return this.http
            .get(`${environment.apiUrl}/api/admin/widget/use`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }


    getCAData() {
        return this.http
            .get(`${environment.apiUrl}/api/admin/widget/ca`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }



    getCAStateData() {
        return this.http
            .get(`${environment.apiUrl}/api/admin/widget/castate`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }



}
