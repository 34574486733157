import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from "@ionic/storage";

export class AbonnementApi {

  constructor(private storage: Storage, private http: HttpClient) {

  }

  get() {
      return this.http
        .get(`${environment.apiUrl}/api/abonnements`)
        .toPromise()
        .then(async p => {
          if (p) {
            return p;
          } else {
            return null;
          }});
  }


    getUserAbonnements(userId) {
        return this.http
            .get(`${environment.apiUrl}/api/abonnements/${userId}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }


    getPaiementInfo(abonnement,back) {
        const ref = environment.apiUrl+"/sogecommerce?back="+encodeURIComponent(back);

        return this.http
            .get(`${environment.apiUrl}/api/paiement/abonnement/${abonnement.id}?back=${ref}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }


    postAbonnement(userAbonnement : any) {
        return this.http
            .post(`${environment.apiUrl}/api/abonnement`, userAbonnement)
            .toPromise();
    }

    patchAbonnement(userAbonnementId : any, userAbonnement : any) {
        return this.http
            .patch(`${environment.apiUrl}/api/abonnement/${userAbonnementId}`, userAbonnement)
            .toPromise();
    }

    deleteAbonnement(userAbonnementId : any) {
        return this.http
            .delete(`${environment.apiUrl}/api/abonnement/${userAbonnementId}`)
            .toPromise();
    }


}
