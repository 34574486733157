import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from '@ionic/storage';
import TemplateProgramme from '../../common/models/TemplateProgramme';
import TemplateCreation from '../../common/models/TemplateCreation';

export class TemplateProgrammesApi {

  constructor(private storage: Storage, private http: HttpClient) {

  }

  public getAll(patientId: string, startIndex?: number, length?: number, type?: string): Promise<TemplateProgramme[]> {
    let params = null;

    if (length != null) { params = '?length=' + length; }
    if (startIndex != null) {
      params = params === null ? '?' : params + '&';
      params = params + 'start_index=' + startIndex;
    }
    if (type !== null) {
      params = params === null ? '?' : params + '&';
      params = params + 'type=' + type;
    }

    return this.http.get<TemplateProgramme[]>(
        `${environment.apiUrl}/api/patients/${patientId}/templates${params}`
    )
        .toPromise();
  }

  public get(patientId: string, templateId: string): Promise<TemplateProgramme> {
    return this.http.get<TemplateProgramme>(
        `${environment.apiUrl}/api/patients/${patientId}/templates/${templateId}`
    )
        .toPromise();
  }

  public create(patientId: string, template: TemplateCreation): Promise<TemplateProgramme> {
    return this.http.post<TemplateProgramme>(
        `${environment.apiUrl}/api/patients/${patientId}/templates`,
        template
    )
        .toPromise();
  }

  public update(patientId: string, templateId: string, template: TemplateCreation): Promise<TemplateProgramme> {
    return this.http.put<TemplateProgramme>(
        `${environment.apiUrl}/api/patients/${patientId}/templates/${templateId}`,
        template
    )
        .toPromise();
  }

  public delete(patientId: string, templateId: string): Promise<void> {
    return this.http.delete<void>(
        `${environment.apiUrl}/api/patients/${patientId}/templates/${templateId}`
    )
        .toPromise();
  }


  public getAllFromUser(startIndex?: number, length?: number, type?: string): Promise<TemplateProgramme[]> {
    let params = null;

    if (length != null) { params = '?length=' + length; }
    if (startIndex != null) {
      params = params === null ? '?' : params + '&';
      params = params + 'start_index=' + startIndex;
    }
    if (type != null) {
      params = params === null ? '?' : params + '&';
      params = params + 'type=' + type;
    }

    return this.http.get<TemplateProgramme[]>(
        `${environment.apiUrl}/api/templates${params}`
    )
        .toPromise();
  }

  public getFromUser(templateId: string): Promise<TemplateProgramme> {
    return this.http.get<TemplateProgramme>(
        `${environment.apiUrl}/api/templates/${templateId}`
    )
        .toPromise();
  }

  public createFromUser(template: TemplateCreation): Promise<TemplateProgramme> {
    return this.http.post<TemplateProgramme>(
        `${environment.apiUrl}/api/templates`,
        template
    )
        .toPromise();
  }

  public updateFromUser(templateId: string, template: TemplateCreation): Promise<TemplateProgramme> {
    return this.http.put<TemplateProgramme>(
        `${environment.apiUrl}/api/templates/${templateId}`,
        template
    )
        .toPromise();
  }

  public deleteFromUser(templateId: string): Promise<void> {
    return this.http.delete<void>(
        `${environment.apiUrl}/api/templates/${templateId}`
    )
        .toPromise();
  }
}
