export const environment = {
  production: false,

  appUrl: 'http://localhost:8100',

  apiUrl: 'https://dev.feelity.fr/api.eyesoft.fr/eyesoft_api/public',
  apiUrlPrivated: 'https://dev.feelity.fr/api.eyesoft.fr/eyesoft_api/public/api',
  apiUrlProtected: 'https://dev.feelity.fr/api.eyesoft.fr/eyesoft_api/public/protected',
  tokenUrl: 'https://dev.feelity.fr/api.eyesoft.fr/eyesoft_api/public/oauth/v2/token',
  apiClient: '1_aovikfmdycwso00g4c4w8wk404g0s4kkw0s8o0ksskk84sows',
  apiSecret: '1jp61al730ckcgoss00s0w00gsswo0k0scg400c0sc044s4w08',
    mqtt: {
        server: 'websocket.iot.fr-par.scw.cloud',
        protocol: "wss",
        port: 443
    }
};
