import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from "@ionic/storage";

export class AuthApi {
  constructor(private storage: Storage, private http: HttpClient) {

  }

  postForgotPassword(email) {
      const params = {
          email : email,
          reset_path:window.location.origin+'/auth/reset/%TOKEN%'
      };

      return this.http
          .post(`${environment.apiUrl}/public/api/auth/forgot-password`, params )
          .toPromise();
  }


  postResetPasswordPassword(token : string, password: string, repassword: string) {
      const params = {
              token : token,
              password: password,
              password_conf: repassword
      };

      return this.http
          .post(`${environment.apiUrl}/public/api/auth/reset-password`, params )
          .toPromise();
  }


}
