import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from "@ionic/storage";

export class ProfilApi {

  constructor(private storage: Storage, private http: HttpClient) {

  }

  getMe(userId = null) {

      if(userId == null){
          return this.http
              .get(`${environment.apiUrl}/api/profil/me`)
              .toPromise()
              .then(async p => {
                  if (p) {
                      await this.storage.set('user', p);
                      return p;
                  } else {
                      return null;
                  }});
      }


    return this.http
        .get(`${environment.apiUrl}/api/profil-data/${userId}`)
        .toPromise()
        .then(async p => {
          if (p) {
            return p;
          } else {
            return null;
          }});
  }


  removeAllNotifications() {
        return this.http
            .delete(`${environment.apiUrl}/api/profil/me/notifications`)
            .toPromise();
  }


  removeNotification( notificationId) {
        return this.http
            .delete(`${environment.apiUrl}/api/profil/me/notifications/${notificationId}`)
            .toPromise();
  }

  markAsReadNotification(notificationId) {
        return this.http
            .patch(`${environment.apiUrl}/api/profil/me/notifications/${notificationId}`, { })
            .toPromise();
  }


    getNotifications(length = 5, startIndex = 0) {
        let params = "?length=" + length;

        if (startIndex != null) {
            params = params + "&start_index=" + startIndex;
        }

        return this.http
            .get(`${environment.apiUrl}/api/profil/me/notifications${params}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }
            });
  }


  getInvoices(userId,length = 5, startIndex = 0, search = null) {
      let params = "?length=" + length;

      if (startIndex != null) {
          params = params + "&start_index=" + startIndex;
      }
      if (search != null) {
          params = params + "&search=" + search;
      }

      return this.http
          .get(`${environment.apiUrl}/api/profil-data/${userId}/invoices${params}`)
          .toPromise()
          .then(async p => {
              if (p) {
                  return p;
              } else {
                  return null;
              }
          });
  }

  getDevices(userId,length = 5, startIndex = 0, search = null) {
        let params = "?length="+length;

        if(startIndex != null){ params = params + "&start_index="+startIndex; }
        if(search != null){ params = params + "&search="+search; }

        return this.http
            .get(`${environment.apiUrl}/api/profil-data/${userId}/devices${params}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
  }

    getDeviceById(deviceId) {
        return this.http
            .get(`${environment.apiUrl}/api/profil-data/device/${deviceId}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }

  addDevice(userId,deviceId : any) {
        return this.http
            .post(`${environment.apiUrl}/api/profil-data/${userId}/devices/${deviceId}`,{})
            .toPromise();
  }

  deleteDevice(userId,deviceId : any) {
        return this.http
            .delete(`${environment.apiUrl}/api/profil-data/${userId}/devices/${deviceId}`)
            .toPromise();
  }

  patchMe(userId,user : any) {
        return this.http
            .patch(`${environment.apiUrl}/api/profil-data/${userId}`, user)
            .toPromise();
  }


}
