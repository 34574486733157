import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from "@ionic/storage";

export class InvoiceApi {

  constructor(private storage: Storage, private http: HttpClient) {

  }

  get(length = 5, startIndex = 0, search = null) {
      let params = "?length="+length;

      if(startIndex != null){ params = params + "&start_index="+startIndex; }
      if(search != null){ params = params + "&search="+search; }

    return this.http
        .get(`${environment.apiUrl}/api/invoices${params}`)
        .toPromise()
        .then(async p => {
          if (p) {
            return p;
          } else {
            return null;
          }});
  }

  getPaiementInfo(invoice,back) {
        const ref = environment.apiUrl+"/sogecommerce?back="+encodeURIComponent(back);

        return this.http
            .get(`${environment.apiUrl}/api/paiement/invoice/${invoice.reference}?back=${ref}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }


  download(invoice:any){
      return this.http.get(`${environment.apiUrl}/api/invoices/${invoice.reference}.pdf`,{ responseType: 'blob' }).subscribe(x => {
          // It is necessary to create a new blob object with mime-type explicitly set
          // otherwise only Chrome works like it should
          var newBlob = new Blob([x], { type: "application/pdf" });

          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              return;
          }

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);

          var link = document.createElement('a');
          link.href = data;
          link.download = invoice.reference+".pdf";
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
              link.remove();
          }, 100);
      });;
  }

    postInvoice(invoice : any) {
        return this.http
            .post(`${environment.apiUrl}/api/invoices`, invoice)
            .toPromise();
    }


}
