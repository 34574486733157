import {AuthConfig} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment";

export const authConfig: AuthConfig = {
    issuer: environment.apiUrl,
    redirectUri: window.location.origin,
    clientId: environment.apiClient,
    dummyClientSecret: environment.apiSecret,
    scope: "SCOPE_USER",
    requireHttps: true,
    tokenEndpoint: environment.tokenUrl,
    userinfoEndpoint: `${environment.apiUrl}/me`,
    oidc: false
};
