import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from './common/guard/auth-guard.service';
import {WizardGuardService} from './common/guard/wizard-guard.service';
import {Error404PageModule} from './home/error404/error404.module';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'signin-wizard',
    loadChildren: () => import('./signin-wizard/signin-wizard.module').then( m => m.SigninWizardPageModule),
    canActivate: [ WizardGuardService ]
  },
  {
    path        : '**',
    pathMatch   : 'full',
    loadChildren: () => import('./home/error404/error404.module').then( m => m.Error404PageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
