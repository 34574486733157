import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from "@ionic/storage";

export class OtherApi {
  constructor(private storage: Storage, private http: HttpClient) {

  }

  sendCodeEmail(email,code) {
      const params = {
          email : email,
          code: code
      };

      return this.http
          .post(`${environment.apiUrl}/api/code/send`, params )
          .toPromise();
  }


    testCode(code) {
        return this.http
            .get(`${environment.apiUrl}/api/code/${code}`)
            .toPromise();
    }



}
