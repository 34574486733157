import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from '@ionic/storage';

export class PatientProgrammesApi {

  constructor(private storage: Storage, private http: HttpClient) {

  }

  get(patientId, length = 5, startIndex = 0) {
    let params = '?length=' + length;
    if (startIndex != null){ params = params + '&start_index=' + startIndex; }

    return this.http
        .get(`${environment.apiUrl}/api/patients/${patientId}/programmes${params}`)
        .toPromise()
        .then(async p => {
          if (p) {
            return p;
          } else {
            return null;
          }});
  }

  postPatientProgramme(patientId: any, patientProgramme: any) {
        return this.http
            .post(`${environment.apiUrl}/api/patients/${patientId}/programmes`, patientProgramme)
            .toPromise();
  }

  deleteProgramme(patientId: any, programmeId: any) {
    return this.http
        .delete(`${environment.apiUrl}/api/patients/${patientId}/programmes/${programmeId}`)
        .toPromise();
  }

  getProgramme(patientId: any, programmeId: any) {
      return this.http
            .get(`${environment.apiUrl}/api/patients/${patientId}/programmes/${programmeId}`)
            .toPromise();
  }

}
