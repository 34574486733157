import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from "@ionic/storage";

export class UserApi {

  constructor(private storage: Storage, private http: HttpClient) {

  }

  get(length = 5, startIndex = 0, search = null) {
      let params = "?length="+length;

      if(startIndex != null){ params = params + "&start_index="+startIndex; }
      if(search != null){ params = params + "&search="+search; }

    return this.http
        .get(`${environment.apiUrl}/api/users${params}`)
        .toPromise()
        .then(async p => {
          if (p) {
            return p;
          } else {
            return null;
          }});
  }

    getUserTeleConsultingData(length = 5, startIndex = 0, startDate:any,endDate:any) {
        let params = "?length="+length;

        if(startIndex != null){ params = params + "&start_index="+startIndex; }
      if(startDate != null){ params = params + "&start_date="+startDate; }
      if(endDate != null){ params = params + "&end_date="+endDate; }

        return this.http
            .get(`${environment.apiUrl}/api/teleconsultings/users${params}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
  }


  getById(userId) {
        return this.http
            .get(`${environment.apiUrl}/api/users/${userId}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
  }


  postUser(user : any) {
        return this.http
            .post(`${environment.apiUrl}/api/users`, user)
            .toPromise();
  }

  patchUser(userId : any, user : any) {
        return this.http
            .patch(`${environment.apiUrl}/api/users/${userId}`, user)
            .toPromise();
  }

  deleteUser(userId : any) {
        return this.http
            .delete(`${environment.apiUrl}/api/users/${userId}`)
            .toPromise();
  }

}
