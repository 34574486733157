import {LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import {IonicModule, IonicRouteStrategy, NavParams} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicStorageModule} from '@ionic/storage';
import { OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import { HttpClient, HttpClientModule} from '@angular/common/http';
import { environment } from '../environments/environment';
import {GlobalModule} from './common/global.module';
import {IMqttClient, IMqttServiceOptions, MqttModule} from 'ngx-mqtt';

registerLocaleData(localeFr);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function storageFactory() : OAuthStorage {
  return localStorage;
}

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  connectOnCreate:false
};


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot( {
      swipeBackEnabled: false,
      mode: 'ios',
      scrollAssist: false,
      scrollPadding: false,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrlPrivated, environment.apiUrlProtected],
        sendAccessToken: true
      }
    }),
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS)
    ],
  providers: [
    StatusBar,
    SplashScreen,
    NavParams,
    {provide: LOCALE_ID, useValue: "fr-FR"},
    { provide: OAuthStorage, useFactory: storageFactory },
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
