import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from '@ionic/storage';

export class PatientApi {

  constructor(private storage: Storage, private http: HttpClient) {

  }

  get(length = 5, startIndex = 0, search = null, filter = null) {
    let params = '?length=' + length;

    if (startIndex != null){ params = params + '&start_index=' + startIndex; }
    if (search != null){ params = params + '&search=' + search; }
    if (filter != null){ params = params + '&filter=' + filter; }

    return this.http
        .get(`${environment.apiUrl}/api/patients${params}`)
        .toPromise()
        .then(async p => {
          if (p) {
            return p;
          } else {
            return null;
          }});
  }


  getById(patientId) {
        return this.http
            .get(`${environment.apiUrl}/api/patients/${patientId}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
  }


  postPatient(patient : any) {
        return this.http
            .post(`${environment.apiUrl}/api/patients`, patient)
            .toPromise();
  }

  patchPatient(patientId : any, patient : any) {
        return this.http
            .patch(`${environment.apiUrl}/api/patients/${patientId}`, patient)
            .toPromise();
  }

  deletePatient(patientId : any) {
        return this.http
            .delete(`${environment.apiUrl}/api/patients/${patientId}`)
            .toPromise();
  }

}
