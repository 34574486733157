import {EventEmitter, Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {ProfilApi} from './apis/profile.api';
import {Storage} from '@ionic/storage';
import {AuthApi} from './apis/auth.api';
import {UserApi} from './apis/user.api';
import {PatientApi} from './apis/patient.api';
import {InvoiceApi} from './apis/invoice.api';
import {DeviceApi} from './apis/device.api';
import {AbonnementApi} from './apis/abonnement.api';
import {OtherApi} from './apis/other.api';
import {PatientNotesApi} from './apis/patientNotes.api';
import {AdminWidgetApi} from './apis/adminWidget.api';
import {ExercicesApi} from './apis/exercices.api';
import {PatientProgrammesApi} from './apis/patientProgrammes.api';
import {CollaborateurApi} from './apis/collaborateur.api';
import {TemplateProgrammesApi} from './apis/templateProgrammes.api';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _profilApi: ProfilApi;
  private _authApi: AuthApi;
  private _userApi: UserApi;
  private _patientApi: PatientApi;
  private _invoiceApi: InvoiceApi;
  private _deviceApi: DeviceApi;
  private _abonnementApi: AbonnementApi;
  private _collaborateurApi: CollaborateurApi;
  private _otherApi: OtherApi;
  private _patientNoteApi: PatientNotesApi;
  private _patientProgrammeApi: PatientProgrammesApi;
  private _adminWidgetApi: AdminWidgetApi;
  private _exercicesApi: ExercicesApi;
  private _templateProgrammeApi: TemplateProgrammesApi;

  refreshUserData: EventEmitter<any> = new EventEmitter();


  constructor(private storage: Storage, private platform: Platform, private http: HttpClient) {
    this._profilApi = new ProfilApi(storage, http);
    this._authApi = new AuthApi(storage, http);
    this._userApi = new UserApi(storage, http);
    this._patientApi = new PatientApi(storage, http);
    this._invoiceApi = new InvoiceApi(storage, http);
    this._deviceApi = new DeviceApi(storage, http);
    this._abonnementApi = new AbonnementApi(storage, http);
    this._collaborateurApi = new CollaborateurApi(storage, http);
    this._otherApi = new OtherApi(storage, http);
    this._patientNoteApi = new PatientNotesApi(storage, http);
    this._patientProgrammeApi = new PatientProgrammesApi(storage, http);
    this._adminWidgetApi = new AdminWidgetApi(storage, http);
    this._exercicesApi = new ExercicesApi(storage, http);
    this._templateProgrammeApi = new TemplateProgrammesApi(storage, http);
  }

  get userApi(): UserApi {
    return this._userApi;
  }

  get authApi(): AuthApi {
    return this._authApi;
  }

  get patientApi(): PatientApi {
    return this._patientApi;
  }

  get profilApi(): ProfilApi {
    return this._profilApi;
  }

  get invoiceApi(): InvoiceApi {
    return this._invoiceApi;
  }

  get deviceApi(): DeviceApi {
    return this._deviceApi;
  }

  get abonnementApi(): AbonnementApi {
    return this._abonnementApi;
  }

  get otherApi(): OtherApi {
    return this._otherApi;
  }

  get patientNoteApi(): PatientNotesApi {
    return this._patientNoteApi;
  }

  get adminWidgetApi(): AdminWidgetApi {
    return this._adminWidgetApi;
  }

  get exercicesApi(): ExercicesApi {
    return this._exercicesApi;
  }

  get patientProgrammeApi(): PatientProgrammesApi {
    return this._patientProgrammeApi;
  }

  get collaborateurApi(): CollaborateurApi {
    return this._collaborateurApi;
  }

  get templateProgrammeApi(): TemplateProgrammesApi {
    return this._templateProgrammeApi;
  }

}
