import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from "@ionic/storage";

export class DeviceApi {

  constructor(private storage: Storage, private http: HttpClient) {

  }

  get(length = 5, startIndex = 0, search = null) {
      let params = "?length="+length;

      if(startIndex != null){ params = params + "&start_index="+startIndex; }
      if(search != null){ params = params + "&search="+search; }

    return this.http
        .get(`${environment.apiUrl}/api/devices${params}`)
        .toPromise()
        .then(async p => {
          if (p) {
            return p;
          } else {
            return null;
          }});
  }


    patchDevice(deviceId : any, device : any) {
        return this.http
            .patch(`${environment.apiUrl}/api/devices/${deviceId}`, device)
            .toPromise();
    }

    postDevice(code : any, device : any) {
        return this.http
            .post(`${environment.apiUrl}/protected/api/devices/${code}`, device)
            .toPromise();
    }

    deleteDevice(deviceId : any) {
        return this.http
            .delete(`${environment.apiUrl}/api/devices/${deviceId}`)
            .toPromise();
    }


}
