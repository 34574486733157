import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from "@ionic/storage";

export class CollaborateurApi {

  constructor(private storage: Storage, private http: HttpClient) {

  }


    getUserCollaborateurs(userId) {
        return this.http
            .get(`${environment.apiUrl}/api/collaborateurs/${userId}`)
            .toPromise()
            .then(async p => {
                if (p) {
                    return p;
                } else {
                    return null;
                }});
    }

    postCollaborateur(userCollaborateur : any) {
        return this.http
            .post(`${environment.apiUrl}/api/collaborateurs`, userCollaborateur)
            .toPromise();
    }

    patchCollaborateur(userCollaborateurId : any) {
        return this.http
            .patch(`${environment.apiUrl}/api/collaborateurs/${userCollaborateurId}`,{

            })
            .toPromise();
    }

    deleteCollaborateur(userCollaborateurId : any) {
        return this.http
            .delete(`${environment.apiUrl}/api/collaborateurs/${userCollaborateurId}`)
            .toPromise();
    }


}
