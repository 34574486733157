import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from "@ionic/storage";

export class ExercicesApi {

  constructor(private storage: Storage, private http: HttpClient) {

  }

  get() {
    return this.http
        .get(`${environment.apiUrlProtected}/api/exercices/descriptor`)
        .toPromise()
        .then(async p => {
          if (p) {
            return p;
          } else {
            return null;
          }});
  }


  deleteExercice(patientId : any, exerciceId : any) {
        return this.http
            .delete(`${environment.apiUrl}/api/patients/${patientId}/exercices/${exerciceId}`)
            .toPromise();
  }


  postExercice(patientId : any, exerciceRef : any, exerciceData : any) {
        return this.http
            .post(`${environment.apiUrl}/api/patients/${patientId}/exercices/${exerciceRef}`, exerciceData)
            .toPromise();
  }

  getEvaluationStat(patientId : any, exerciceRef : any) {
        return this.http
            .get(`${environment.apiUrl}/api/patients/${patientId}/stats/recap/evaluation/${exerciceRef}`)
            .toPromise();
  }


  getEvaluationGlobalRecapStat(patientId : any) {
        return this.http
            .get(`${environment.apiUrl}/api/patients/${patientId}/stats/globalrecap/evaluation`)
            .toPromise();
  }

  getEntrainementStat(patientId : any, exerciceRef : any) {
        return this.http
            .get(`${environment.apiUrl}/api/patients/${patientId}/stats/recap/entrainement/${exerciceRef}`)
            .toPromise();
  }


  getEvaluationStatHistory(patientId : any, filter : any,length = 5, startIndex = 0, startDate = null, endDate = null, chartType = null, argument1 = null, argument2 = null) {
      let params = "?length="+length;
      if(startIndex != null){ params = params + "&start_index="+startIndex; }
      if(filter != null){ params = params + "&filter="+filter; }
      if(startDate != null){ params = params + "&start_date="+startDate; }
      if(endDate != null){ params = params + "&end_date="+endDate; }
      if(chartType != null){ params = params + "&data_type="+chartType; }
      if(argument1 != null){ params = params + "&argument_1="+argument1; }
      if(argument2 != null){ params = params + "&argument_2="+argument2; }


      return this.http
           .get(`${environment.apiUrl}/api/patients/${patientId}/stats/history/evaluation${params}`)
            .toPromise();
  }


  getSeanceStatHistory(patientId : any,length = 5, startIndex = 0) {
        let params = "?length="+length;
        if(startIndex != null){ params = params + "&start_index="+startIndex; }


        return this.http
            .get(`${environment.apiUrl}/api/patients/${patientId}/stats/seances${params}`)
            .toPromise();
  }

  getEntrainementStatHistory(patientId : any, filter : any,length = 5, startIndex = 0, startDate = null, endDate = null, chartType = null, argument1 = null, argument2 = null) {
      let params = "?length="+length;
      if(startIndex != null){ params = params + "&start_index="+startIndex; }
      if(filter != null){ params = params + "&filter="+filter; }
      if(startDate != null){ params = params + "&start_date="+startDate; }
      if(endDate != null){ params = params + "&end_date="+endDate; }
      if(chartType != null){ params = params + "&data_type="+chartType; }
      if(argument1 != null){ params = params + "&argument_1="+argument1; }
      if(argument2 != null){ params = params + "&argument_2="+argument2; }

      return this.http
            .get(`${environment.apiUrl}/api/patients/${patientId}/stats/history/entrainement${params}`)
            .toPromise();
  }
}
